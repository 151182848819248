require.config({
    paths  : {
        'dropzone': 'dropzone-amd-module',
        'notify'  : 'teemo/notify'
    },
    shim   : {
        'foundation'        : ['jquery'],
        'foundation-require': ['jquery', 'foundation'],
        'notify'            : ['jquery', 'foundation-require']
    },
    deps   : [
        'teemo'
    ]
});
